import React from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import cx from 'classnames';
import { BlogPostEditorButton } from 'components/admin';
import { isAdmin } from 'utils/roles';
import Page from 'components/Page';
import BaseSection from 'components/Section';
import BlogPostPacket from 'components/BlogPostPacket';
import Pagination from 'components/Pagination';
import s from './style.module.css';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import { useConfiguration } from 'hooks/configuration';

const Section = styled(BaseSection)`
  background-color: ${({ theme }) => theme.schools.current.primary};
`;

const POSTS_PER_PAGE = 12;
const BLOG_LISTING_QUERY = gql`
  query SchoolBlogQuery($schoolSlug: String, $offset: Int!, $limit: Int!) {
    school(slug: $schoolSlug) {
      id
      name
      slug
      blog {
        id
        postsCount
        posts(limit: $limit, offset: $offset) {
          id
          ...post
        }
      }
    }
  }
  ${BlogPostPacket.fragments.post}
`;

const Heading = styled.h1`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: 400;
  font-family: 'Gotham', Helvetica, Arial, sans-serif;
  letter-spacing: -0.8px;
  color: #4a4a4a;
  font-size: 20px;
  text-align: center;
  margin: 20px 0;
  padding: 0 20px;
  color: white;

  font-size: 36px;
  line-height: 46px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 30px 0 20px 0;
    padding: 0;
    font-size: 52px;
    line-height: 58px;
  }

  &:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: 'Latest posts';
    display: block;
    font-size: 20px;
    line-height: 12px;
    margin: 15px 0 10px 0;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: 26px;
      line-height: 18px;
      margin: 26px 0 17px 0;
    }
  }
`;

const BlogListing = ({ params: { schoolSlug, pageNumber = 1 } }) => {
  const { currentUser } = useCurrentUser();
  const { domain } = useConfiguration();

  const variables = {
    schoolSlug,
    offset: (parseInt(pageNumber, 10) - 1) * POSTS_PER_PAGE,
    limit: POSTS_PER_PAGE,
  };
  const { data, loading, error } = useQuery(BLOG_LISTING_QUERY, {
    variables,
    ssr: true,
  });
  if (!data || loading) return <Page />;

  const showEditor = isAdmin(currentUser);
  const { school } = data;
  const { blog, name, slug } = school;
  const page = parseInt(pageNumber, 10);
  const totalPages = Math.ceil(blog.postsCount / POSTS_PER_PAGE);
  const pagePath = page === 1 ? '' : `/page/${page}`;
  return (
    <Page>
      <Helmet>
        <title>{`Blog - ${name} - Page ${page}`}</title>
        <link rel="canonical" href={`${domain.url}/${slug}/blog${pagePath}`} />
        {page - 1 > 0 && (
          <link rel="prev" href={`${domain.url}/${slug}/blog/page/${page - 1}`} />
        )}
        {page + 1 <= totalPages && (
          <link rel="next" href={`${domain.url}/${slug}/blog/page/${page + 1}`} />
        )}
      </Helmet>
      {showEditor && <BlogPostEditorButton post={{ school }} blog={blog} />}
      <Section>
        <Heading>{name} Blog</Heading>
        <ol
          className={cx(s.grid, { [s.frontpage]: page === 1 })}
          start={(parseInt(pageNumber, 10) - 1) * POSTS_PER_PAGE + 1}
        >
          {blog.posts.map((post, idx) => (
            <li key={post.id}>
              <BlogPostPacket
                post={post}
                school={school}
                maxWidth={false}
                large={idx === 0 && page === 1}
              />
            </li>
          ))}
        </ol>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          hrefBuilder={(selected) =>
            selected === 0 ? `/${slug}/blog` : `/${slug}/blog/page/${selected + 1}`
          }
          outline
          white
        />
      </Section>
    </Page>
  );
};

export default BlogListing;
