import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import JsonLd from 'components/JsonLd';
import { assetUrl } from 'components/AssetImage';
import { isAdmin } from 'utils/roles';
import Page from 'components/Page';
import TrustPilotSummary from 'components/sections/TrustPilotSummary';
import EnterpriseSales from './components/EnterpriseSales';
import Cpd from './components/Cpd';
import ProductHeader from 'components/sections/ProductHeader';
import CourseVideoPreview from 'components/sections/CourseVideoPreview';
import CourseOutline from 'components/sections/CourseOutline';
import ProductPackageChooser from 'components/sections/ProductPackageChooser';
import CourseDescription from 'components/sections/CourseDescription';
import ExpertProfile from 'components/ExpertProfile';
import CollectionOutline from 'components/sections/CollectionOutline';
import IncludedInCollections from 'components/sections/IncludedInCollections';
import ClassroomExplainer from 'components/sections/ClassroomExplainer';
import WhatOurCommunityAreSaying from 'components/WhatOurCommunityAreSaying';

import RhsModules from './components/RhsModules';
import RhsFaq from './components/RhsFaq';
import RhsTrustPilotSummary from './components/RhsTrustpilotSummary';

import { ProductEditorButton } from 'components/admin';
import findMetaTags from 'utils/findMetaTags';
import { useEventBus } from '@lwe/toolkit/utils';
import { PRODUCT_VIEWED } from '@root/events';

import useCurrencyCode from 'hooks/useCurrencyCode';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import PageNotFound from 'components/PageNotFound';
import { useConfiguration } from 'hooks/configuration';
import s from './style.module.css';

const PRODUCT_DETAIL_QUERY = gql`
  query ProductDetail($schoolSlug: String!, $productSlug: String!) {
    school(slug: $schoolSlug) {
      id
      slug
      name
      product(slug: $productSlug) {
        id
        slug
        metaTitle
        metaDescription
        visible
        shortDescription
        trailerSynopsis
        publishedOn
        availableToBuy
        cpdHours
        school {
          id
          slug
        }
        pricing {
          from {
            current
            formatted {
              current
            }
          }
          to {
            current
          }
        }
        school {
          id
          slug
          name
        }
        tutor {
          id
          ...ExpertProfile__expert
        }
        ...ProductHeader__product
        ...productOutline
        ...productSelector
        ...courseDescription
        ...collectionOutline
        ...includedInCollections
        ...productVideoPreview
        ...rhsModules
      }
      ...ClassroomExplainer__school
    }
  }
  ${ProductHeader.fragments.product}
  ${CourseOutline.fragments.product}
  ${ProductPackageChooser.fragments.product}
  ${CourseDescription.fragments.product}
  ${ExpertProfile.fragments.expert}
  ${CollectionOutline.fragments.product}
  ${IncludedInCollections.fragments.product}
  ${CourseVideoPreview.fragments.product}
  ${ClassroomExplainer.fragments.school}
  ${RhsModules.fragments.product}
`;

const ProductDetail = ({ params: { schoolSlug, productSlug } }) => {
  // TODO: Re-instate experiments
  const variables = { schoolSlug, productSlug };
  const { data } = useQuery(PRODUCT_DETAIL_QUERY, { variables, ssr: true });
  const { settings, domain } = useConfiguration();
  const currencyCode = useCurrencyCode();
  const { currentUser } = useCurrentUser();
  const eventBus = useEventBus();

  useEffect(() => {
    if (data && data.school && data.school.product && currencyCode) {
      eventBus.publish(PRODUCT_VIEWED, { product: data.school.product, currencyCode });
    }
  }, [data, currencyCode]);

  if (!data) return <Page />;
  if (!data.school || !data.school.product) return <PageNotFound />;

  const {
    school,
    school: {
      product,
      product: { tutor, pricing },
    },
  } = data;
  if (!product) return <Page />;

  const {
    metaTitle = product.title,
    title,
    shortDescription,
    isBundle,
    collections,
    requirements,
    files: { header, grid },
    availableToBuy,
    slug,
  } = product;
  const productImageUuid = grid?.uuid || header?.uuid;
  const productImage = productImageUuid ? assetUrl(productImageUuid) : '';
  const productType = isBundle ? 'bundle' : 'course';
  const metaTags = findMetaTags({ type: productType, ...product }, settings);
  const showEditor = isAdmin(currentUser);
  const schoolName = school.name;
  const pricingFrom = pricing.from.current;
  const pricingTo = pricing.to.current;
  const productUrl = `${domain.url}/${school.slug}/${product.slug}`;
  const isRHS = slug === 'rhs-level-2-collection';

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    mainEntity: {
      '@type': 'Product',
      name: title,
      image: productImage,
      category: schoolName,
      description: shortDescription,
      url: productUrl,
      offers: [
        {
          '@type': 'AggregateOffer',
          url: productUrl,
          priceCurrency: currencyCode,
          lowPrice: pricingFrom,
          highPrice: pricingTo,
          availability: 'https://schema.org/OnlineOnly',
        },
      ],
    },
  };

  return (
    <Page>
      {showEditor && <ProductEditorButton product={product} />}
      <Helmet title={`${metaTitle || product.title}`} meta={metaTags} />
      <JsonLd data={schemaData} />
      <ProductHeader product={product} />
      <CourseDescription product={product} />
      {isRHS && <RhsModules product={product} />}
      <Cpd product={product} />
      {!isBundle && <CourseOutline product={product} />}
      {isBundle && !isRHS && <CollectionOutline product={product} />}
      {isRHS ?
        <RhsTrustPilotSummary />
        :
        <TrustPilotSummary school={school.slug} />
      }
      {availableToBuy && <ProductPackageChooser product={product} />}
      {availableToBuy && <EnterpriseSales product={product} />}
      {isRHS && <RhsFaq />}
      <ClassroomExplainer school={school} color={isRHS ? 'light' : null} />
      <ExpertProfile tutor={tutor} />
      {collections && <IncludedInCollections product={product} />}
      <WhatOurCommunityAreSaying />
    </Page>
  );
};

ProductDetail.propTypes = {
  basket: PropTypes.object,
};

export default ProductDetail;
