import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Helmet from 'react-helmet';
import JsonLd from 'components/JsonLd';
import LatestBlogPosts from 'components/LatestBlogPosts';
import LatestQuestionAnswer from './components/LatestQuestionAnswer';
import About from './components/About';
import Page from 'components/Page';
import PageNotFound from 'components/PageNotFound';
import { assetUrl } from 'components/AssetImage';
import Header from './components/Header';
import ProductCard from 'components/ProductCard';
import ProductsRow from './components/ProductsRow';
import groupBy from 'lodash/collection/groupBy';
import { useConfiguration } from 'hooks/configuration';

const EXPERT_QUERY = gql`
  query ExpertQuery($expertSlug: String!) {
    schools {
      id
      slug
      name
    }
    expert: tutor(slug: $expertSlug) {
      id
      blogSchool: school {
        id
        slug
      }
      school {
        id
        slug
        name
      }
      profile {
        id
        name
        biog
        quote
        quoteCitation
        metaTitle
        metaDescription
        avatar {
          id
          uuid
        }
      }
      products {
        id
        ...ProductCard__product
      }
      blogPosts(limit: 3) {
        id
        ...LatestBlogPosts__posts
      }
    }
  }
  ${ProductCard.fragments.product}
  ${LatestBlogPosts.fragments.posts}
`;

const Expert = ({ params: { expertSlug } }) => {
  const variables = { expertSlug };
  const { domain } = useConfiguration();
  const { data } = useQuery(EXPERT_QUERY, {
    variables,
    ssr: true,
  });
  if (!data || !data.expert) return <Page />;
  const { expert, schools } = data;
  const {
    latestAnswer,
    blogPosts,
    blogSchool,
    profile: { name, metaTitle, metaDescription, shortBiog, avatar } = {},
  } = expert;
  const productsBySchool = groupBy(expert.products, (p) => p.school.slug);

  const expertImage = assetUrl(avatar?.uuid);
  const expertUrl = `${domain.url}/experts/${expertSlug}`;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    mainEntity: {
      '@type': 'Person',
      name: name,
      url: expertUrl,
      image: expertImage,
      description: shortBiog,
    },
  };

  return (
    <Page>
      <Helmet title={`${metaTitle || name}`} description={metaDescription} />
      <JsonLd data={schemaData} />
      <Header expert={expert} />
      <About expert={expert} />
      {schools.map(
        (school) =>
          productsBySchool[school.slug] !== undefined && (
            <ProductsRow school={school} products={productsBySchool[school.slug]} />
          ),
      )}
      {blogPosts && <LatestBlogPosts posts={blogPosts} heading="Latest blog posts" centered />}
      {latestAnswer && <LatestQuestionAnswer questionAnswer={latestAnswer} expert={expert} />}
    </Page>
  );
};

export default Expert;
