import useScript from 'react-script-hook';
import { isSSR } from 'utils';
import { USER_AUTHENTICATED } from '@lwe/toolkit/utils';
import { ADDED_TO_BASKET, CHECKOUT_STEP, PRODUCT_VIEWED } from '@root/events';
import { useEventBusSubscription } from '@lwe/toolkit/utils';
import { assetUrl } from 'components/AssetImage';
import { useConfiguration } from 'hooks/configuration';
import { toLocalId } from '@lwe/toolkit/utils';

if (!isSSR) {
  window._learnq = window._learnq || [];
}

const identify = (user) => window._learnq.push(['identify', user]);
const eventId = (entity) => `${entity.id}_${Math.floor(Date.now() / 1000)}`;
const track = (label, event) => {
  console.log('[klaviyo] track', window._learnq, window._learnq.push(['track', label, event]));
};

const itemsFromBasket = (basket, configuration) =>
  basket.items.map(({ variant }) => {
    return {
      ProductID: variant.product.id,
      SKU: variant.name,
      ProductName: variant.product.title,
      Quantity: 1,
      ItemPrice: variant.price.current,
      RowTotal: variant.price.current,
      ProductURL: `${configuration.domain.url}/${variant.product.school.slug}/courses/${variant.product.slug}`,
      ImageURL: assetUrl(
        variant.product?.files?.poster?.uuid || variant.product?.files?.grid?.uuid,
        1848,
        1054,
      ),
      ProductCategories: [variant.product.title],
    };
  });

const Klaviyo = ({ apiKey }) => {
  const configuration = useConfiguration();

  useScript({
    src: `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey}`,
    checkForExisting: true,
  });

  useEventBusSubscription(USER_AUTHENTICATED, ({ detail: { user } }) => {
    identify({
      $id: toLocalId(user.id),
      $first_name: user.profile.firstName,
      $last_name: user.profile.lastName,
    });
  });

  useEventBusSubscription(ADDED_TO_BASKET, ({ detail: { product, variant, basket } }) => {
    const event = {
      $value: variant.price.current,
      AddedItemProductName: product.title,
      AddedItemProductID: product.id,
      AddedItemSKU: variant.name,
      AddedItemCategories: [product.school.name],
      AddedItemImageURL: assetUrl(
        product?.files?.poster?.uuid || product?.files?.grid?.uuid,
        1848,
        1054,
      ),
      AddedItemURL: `${configuration.domain.url}/${product.school.slug}/courses/${product.slug}`,
      AddedItemPrice: variant.price.current,
      AddedItemQuantity: 1,
      ItemNames: [product.title],
      CheckoutURL: `${configuration.domain.url}/basket/checkout`,
      Items: itemsFromBasket(basket, configuration),
    };
    console.log('[klaviyo] added to cart', event);
    track('Added to Cart', event);
  });

  useEventBusSubscription(CHECKOUT_STEP, ({ detail: { step, basket } }) => {
    if (step !== 1) return;

    const event = {
      $event_id: eventId(basket),
      $value: 29.98,
      ItemNames: basket.items.map(({ variant }) => variant.product.title),
      CheckoutURL: `${configuration.domain.url}/basket/checkout`,
      Categories: basket.items.map(({ variant }) => variant.product.school.name),
      Items: itemsFromBasket(basket, configuration),
    };
    console.log('[klaviyo] started checkout', event);
    track('Started Checkout', event);
  });

  useEventBusSubscription(PRODUCT_VIEWED, ({ detail: { product } }) => {
    const event = {
      ProductName: product.title,
      ProductID: product.id,
      // SKU: "WINNIEPOOH",
      Categories: [product.school.name],
      ImageURL: assetUrl(product?.files?.poster?.uuid || product?.files?.grid?.uuid, 1848, 1054),
      URL: `${configuration.domain.url}/${product.school.slug}/courses/${product.slug}`,
      Brand: configuration.slug,
      Price: product.pricing.from.current,
      CompareAtPrice: product.pricing.from.current,
    };
    console.log('[klaviyo] product viewed', event);
    track('Viewed Product', event);
  });

  return null;
};

export default Klaviyo;
