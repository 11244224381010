import React, { useMemo } from 'react';
import styled from 'styled-components';
import YouTubePlayer from 'components/YouTubePlayer';
import ImageBlock from './components/ImageBlock';
import AssetImageBlock from './components/AssetImageBlock';
import RecommendedProduct from 'components/RecommendedProduct';
import { Body, Subtitle } from 'components/Typography';
import { useConfiguration } from 'hooks/configuration';

const BLOCKS = {
  PARAGRAPH: 'paragraph',
  IMAGE: 'image',
  ASSET_IMAGE: 'asset-image',
  HEADING_ONE: 'heading1',
  HEADING_TWO: 'heading2',
  HEADING_THREE: 'heading3',
  HEADING_FOUR: 'heading4',
  BULLETED_LIST: 'bulleted-list',
  NUMBERED_LIST: 'numbered-list',
  LIST_ITEM: 'list-item',
  ALT_TEXT: 'alt-text',
  YOUTUBE_PLAYER: 'youtube-video',
  LINK: 'link',
  BLOCKQUOTE: 'block-quote',
  RELATED_PRODUCTS: 'related-products',
};

const isExternalLink = (link, domain) => {
  if (!link) return false;
  return !link?.startsWith(domain.url);
};

const config = {
  renderNode: {
    [BLOCKS.RELATED_PRODUCTS]: ({ node, children, context }) => {
      const products = node.ids
        .map((id) => context.embeddedProducts.find((p) => p.id === id))
        .filter(Boolean);
      if (products.length === 0) return null;
      const [product] = products;
      return <RecommendedProduct product={product} />;
    },
    [BLOCKS.BLOCKQUOTE]: ({ children }) => {
      return (
        <blockquote>
          <p>{children}</p>
        </blockquote>
      );
    },
    [BLOCKS.LINK]: ({ node, children, domain }) => {
      const props = {
        href: node.url,
        ...(isExternalLink(node.url, domain) && { target: '_blank' }),
        children,
      };
      return <a {...props} />;
    },
    [BLOCKS.PARAGRAPH]: ({ children }) => {
      if (children.every((c) => c === '')) return null;
      return <SpacedBody as="p">{children}</SpacedBody>;
    },
    [BLOCKS.IMAGE]: ImageBlock,
    [BLOCKS.ASSET_IMAGE]: AssetImageBlock,
    [BLOCKS.HEADING_ONE]: ({ children }) => <h1>{children}</h1>,
    [BLOCKS.HEADING_TWO]: ({ children }) => <h2>{children}</h2>,
    [BLOCKS.HEADING_THREE]: ({ children }) => <Subtitle as="h3">{children}</Subtitle>,
    [BLOCKS.HEADING_FOUR]: ({ children }) => <h4>{children}</h4>,
    [BLOCKS.BULLETED_LIST]: ({ children }) => <BulletedList>{children}</BulletedList>,
    [BLOCKS.NUMBERED_LIST]: ({ children }) => <NumberedList>{children}</NumberedList>,
    [BLOCKS.LIST_ITEM]: ({ children }) => <Body as="li">{children}</Body>,
    [BLOCKS.YOUTUBE_PLAYER]: ({ node }) => <YouTubePlayer id={node.id} />,
  },
  renderText: ({ text, ...leaf }, key) => {
    if (leaf.bold) text = <strong key={key}>{text}</strong>;
    if (leaf.code) text = <code key={key}>{text}</code>;
    if (leaf.italic) text = <em key={key}>{text}</em>;
    if (leaf.underline) text = <u key={key}>{text}</u>;
    return text;
  },
};

const renderNode = (node, context, i, domain) => {
  const Component = config.renderNode[node.type];
  if (!Component) return config.renderText(node, i);

  return (
    <Component key={i} node={node} context={context} domain={domain}>
      {node.children.map((child, j) => renderNode(child, context, j, domain))}
    </Component>
  );
};

const SlateBody = ({ as: Component = 'div', body, context, ...props }) => {
  const { domain } = useConfiguration();
  const roots = useMemo(() => {
    try {
      if (typeof body === 'string') {
        return JSON.parse(body);
      }
      return body;
    } catch {
      return [];
    }
  }, [body]);
  return (
    <Component {...props}>
      {roots.map((root, i) => renderNode(root, context, i, domain))}
    </Component>
  );
};

export default SlateBody;

const SpacedBody = styled(Body)`
  margin-bottom: 1rem;
`;

const BulletedList = styled(SpacedBody).attrs({ as: 'ul' })`
  list-style: square;
  margin: 20px;
`;

const NumberedList = styled(SpacedBody).attrs({ as: 'ul' })`
  list-style-type: decimal;
  margin-left: 20px;
`;
