import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useConfiguration } from 'hooks/configuration';

const RIVER_COTTAGE_PROMOTIONS_QUERY = gql`
  query RiverCottagePromotionsQuery {
    promotions {
      riverCottageSale: group(name: riverCottageSale) {
        endsAt
      }
    }
  }
`;

export const useRiverCottageSale = () => {
  const { data } = useQuery(RIVER_COTTAGE_PROMOTIONS_QUERY, { ssr: true });
  const configuration = useConfiguration();

  const {
    promotions: { riverCottageSale },
  } = data ?? { promotions: {} };

  return useMemo(() => {
    return {
      showRiverCottageSale: !!riverCottageSale
    };
  }, [riverCottageSale]);
};
